.container {
  min-height: 100vh;
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  padding: 0 16px;
  background-color: #001529;
}

.logo {
  font-size: 18px;
  font-weight: bold;
  color: #fff !important;

  &:link:visited:hover:active:focus {
    color: #fff;
  }

  > img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

.gap {
  flex: 1 1 0%;
}

.notice {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  // padding: 0 35px 0 10px;
  padding: 0 20px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #252a3d;
  }
}

.mail {
  color: #fff;

  > svg {
    width: 22px;
    height: 22px;
  }
}

.my {
  padding: 0 10px;
  color: #fff;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #252a3d;
  }
}

.avatar {
  background-color: #00acad;
}

.company {
  padding: 0 10px;
  color: #fff;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #252a3d;
  }
}

.sider {
  position: sticky !important;
  top: 64px;
  left: 0;
  height: calc(100vh - 64px);
}

.breadcrumb {
  margin: 16px 0;
}

.content {
  margin: 64px 16px 0;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 360px;
  padding: 20px;
  background-color: #fff;
}

.footer {
  text-align: center;
}
